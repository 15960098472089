/* Bulma */
@import "customBulma";

/* FontAwesome */
@import '~@fortawesome/fontawesome-free/css/all.css';

@import "layout";

@import "easydocufont";

/* Dashboard */
div.dashboard-card {
    height: 100%;
}

.machine-badge {
    position: absolute;
    margin-top: -75px;
    margin-left: -15px;
}

.recharts-cartesian-axis-tick-value {
    tspan {
        //font-weight: bold;
        font-size: 10px;
    }
}

tr.is-hoverable:hover {
    background-color: lightgrey !important;
}

.loading {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9000;
    background-color: $gray-darker;
    opacity: 70%;

    &.spinner-active {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        width: 250px;
        height: 250px;

        animation: loading 3.5s linear infinite;
        @keyframes loading {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(359deg);
            }
        }
    }
}

.dropzone {
    width: 100%;
    background-color: $gray-lighter;
    border: 1px dashed black;
    border-radius: 15px;
    padding: 15px;

    .pw-icon-upload:before {
        color: gray;
    }

    &.highlight {
        border-color: $company-red;
        border-style: solid;

        .initial, .with-files {
            pointer-events: none; //make sure that children do not trigger drag events (only dropzone itself)
        }
    }

    .initial {
        display: flex;
        min-height: 100px;
    }

    .with-files {
        .dropzone-file-list {
            display: flex;
            flex-wrap: wrap;

            .dropzone-file {
                display: flex;
                width: 100%;
                flex: 0 50%;
                white-space: nowrap;
                cursor: default;

                .delete-file {
                    color: $btn-background-gray;
                }
            }
        }
    }
}


/* Masonry'ish layout for components */
.device-wall {
    column-count: 2;
    column-gap: 1.5rem;
    column-fill: auto;

    .device-tile {
        position: initial;

        ul ul {
            margin-left: 0;
            padding-left: 30px;
        }

        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        border-radius: 3px;
        margin-top: 2px;
        margin-bottom: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}


/* Material UI Table */
// NOTE: set this in grid's theme when we know how to do that...
.MuiCheckbox-colorSecondary {
    color: black !important;

    &.Mui-checked {
        color: #3273dc !important;
    }
}

.MTableToolbar-highlight-6, .MuiToolbar-root.jss6 {
    color: inherit !important;
    background-color: inherit !important;
}

/* Toast Notifications */
.toast-wrapper {
    width: 500px;

    &.toast-wrapper-top-right {
        top: $headerHeight;
    }

    .toast-container {
        margin: 4px;

        .toast {
            color: black;
            background-color: $gray-lighter !important;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

            .close-button {
                color: $gray-company;
            }

            .toast-layout-icon {
                width: 15%;
                padding-left: 7px;
            }
        }
    }
}

#tos-text {
    p {
        margin-bottom: 20px;
    }

    h1 {
        font-size: larger;
        font-weight: bolder;
        margin-bottom: 20px;
    }
}

#imprint-text {
    h1 {
        font-size: larger;
        font-weight: bolder;
        margin-bottom: 20px;
    }

    h2 {
        font-size: large;
        font-weight: bold;
        margin-top: 20px;
    }
}

#hero-logo {
    @media(max-width: $screen-md-max) {
        height: 100%;
        min-width: 800px;
        overflow: hidden;
    }
    @media(min-width: $screen-l-min) {
        width: 100%;
    }

    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

#open-pdf-modal {
    position: fixed;
    right: 36px;
    bottom: 50px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    z-index: 10;
    opacity: 100 !important;
    &:disabled {
        color: lightgray !important;
        background-color: gray !important;
    }
}

#welds-table {
    margin-bottom: 100px;

    thead .column-description {
        display: none; //dont show description in header but show in column-selection popover
    }
}

.col-sel-item-wrapper{ //NOTE: this is not part of the table
    display: flex;
    align-items: center;

    .column-description{
        margin-left: 5px;
    }
}

// corporate communications needs this code to style the cookie banner. please leave it here!
#cookiebanner button, #cookiebanner .button, #cookiebanner .btn, #cookiebanner [type=submit], #cookiebanner [type=button] {
    padding: 6px 15px !important;
}

.img-wrapper {
    height: 340px;
    position: relative;
    overflow: hidden;
}

#login-link {
    &.button {
        background-color: $companyColor;
    }
    color: white;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');

.hero-headline {
    width: 412px;
    font-size: 36px;
    line-height: 28px;
    text-transform: uppercase;
    position: absolute;
    top: 100px;
    font-weight: 700;

    /* fix positioning of splashscreen title for small resolutions */
    @media(min-width: $screen-sm-min) {
        right: 20vw;
    }

    .headline-red {
        background-color: white;
        display: block;
        padding: 8px;
        color: $companyColor;
        box-decoration-break: clone;
        word-wrap: break-word;
    }

    .headline-device {
        background-color: white;
        width: 240px;
        display: block;
        padding: 8px;
    }
}

// Cookiebot styling for Markus Ablinger
.cbOverrideButtonCustom {
    padding: 6px 15px !important;
}