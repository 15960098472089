﻿/* Screen resolutions */
$screen-xs-min: 0px;
$screen-xs-max: 768px;
$screen-sm-min: 769px;
$screen-sm-max: 1023px;
$screen-md-min: 1024px;
$screen-md-max: 1215px;
$screen-l-min: 1216px;
$screen-l-max: 1407px;
$screen-xl-min: 1408px;

/* App specific sizes */
$navbarHeight: 48px;
$headerHeight: 55px;
$footerHeight: 30px;
$sidebarWidth: 230px;
$sidebarWidthSmall: 62px;