@import "colors";
@import "sizes";

html, body, #elmish-app {
    height: 100%;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
}

/* Footer */
.footer-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $footerHeight;
    width: 100%;
    background-color: $navbarBgColor;
    color: white;
    font-size: 13px;

    a {
        color: white;
        padding-left: 12px;
        padding-right: 12px;
        border-left: 1px solid white; //vertical lines between items

        &:hover {
            color: $brand-info;
        }
    }
    a:first-of-type {
        border: none;
    }
}

/* Top header */
.header-top {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 !important;
    width: 100%;
    height: $headerHeight;
    z-index: 1000;
    border-bottom: none;
    background-color: $navbarBgColor;
    background-image: none;

    @media(min-width: $screen-sm-min) {
        padding-left: 20px;
        padding-right: 20px;
    }

    .level{
        margin-bottom: 0;
        height: 100%
    }

    &.red-border {
        border-bottom: 2px solid $company-red;
    }

    #logo-and-name {
        display: flex;
        align-items: center;
        color: white;
        cursor: pointer;
        height: 100%;
    }


    /* User menu */
    #header-user {
        cursor: pointer;
        height: 100%;
        width: calc(#{$headerHeight} - 2px);
        color: white;

        .dropdown {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            &.is-active {
                height: calc(100% + 4px); //icon should cover red border when active
            }
            &:hover, &.is-active  {
                color: black;
                background-color: $gray-lighter;
            }

            .dropdown-menu {
                cursor: initial;
                padding: 0;
                .dropdown-content {
                    background-color: $gray-lighter;
                    color: black;
                    padding: 0;

                    .dropdown-item {
                        white-space: nowrap;
                        color: black;
                        padding: 8px;

                        &.is-clickable:hover {
                            background-color: white;
                        }

                        .icon {
                            margin-left: 10px;
                            margin-right: 10px;
                        }

                        .item-text {
                            margin-right: 10px;
                        }

                        a{
                            color: black;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }


    #version-info {
        color: $gray-text;
        float: right;
        margin-left: 10px;
        margin-top: 6px;
        font-size: 10px;
    }

    #header-logo {
        display: inline-block !important;
        margin-right: 10px;
        vertical-align: middle;
        height: 24px;

        @media(max-width: $screen-sm-max) {
            height: 20px;
        }
    }

    #header-product-name {
        color: white;
        font-size: 18px;
        vertical-align: middle;
        font-weight: bold;
    }
}


/* Main content */
.main-content {
    position: relative;
    padding-top: calc(#{$headerHeight} + 32px) !important;
    min-height: calc(100% - #{$footerHeight});
    left: 0;
    width: 100%;
    &.section {
        padding-top: 2rem;
        padding-bottom: 0rem;
    }

    .modal {
        z-index: 2000;
        .modal-card-body {
            padding: 30px 70px 30px 70px;
        }

        /* Width of modal dialog*/
        @media screen and (min-width: 769px){
            .modal-content, .modal-card {
                width: 769px;
            }
        }
    }

    .toast-message {
        z-index: 3000;
    }
}

.landingpage-content {
    position: relative;
    padding-top: $headerHeight !important;
    min-height: calc(100% - #{$footerHeight});
    left: 0;
    width: 100%;
    &.section {
        padding-top: 2rem;
        padding-bottom: 0rem;
    }

    .modal {
        z-index: 2000;
        .modal-card-body {
            padding: 30px 70px 30px 70px;
        }

        /* Width of modal dialog*/
        @media screen and (min-width: 769px){
            .modal-content, .modal-card {
                width: 769px;
            }
        }
    }

    .toast-message {
        z-index: 3000;
    }
}


.navbar {
    height: 100%;
    background-color: inherit;
    color: white;

    .navbar-burger {
        color: white;
        height: 100%;
    }

    .navbar-brand {
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
    }

    .navbar-menu {
        background-color: inherit;
        .navbar-item {
            color: white;
            &:hover {
                color: black;
            }
        }
    }
}


.ui-jqchart {
    page-break-inside: avoid;
    margin-bottom: 5px;


    @media(max-width: $screen-xs-max) {
        margin-bottom: 40px;
    }
}

/* fix detailed alignment: TODO: adjust for new buttons */
.fa-skull-crossbones {
    padding-left: 4px;
}


/*** Global overrides for print layout ***/
@media print {
    body {
        font-size: 10px !important;
        padding: 0;
        width: 100%;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 1cm;
    }

    .menu li:not(.is-active) {
        display: none;
    }
}
