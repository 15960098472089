$icomoon-font-family: "EasyDocuFont" !default;
$icomoon-font-path: "fonts" !default;

$pw-icon-EasyJob: "\e902";
$pw-icon-PDF: "\e908";
$pw-icon-Syn-ID: "\e90b";
$pw-icon-date: "\e900";
$pw-icon-duration: "\e901";
$pw-icon-error: "\e914";
$pw-icon-i: "\e903";
$pw-icon-ie: "\e904";
$pw-icon-info-1: "\e913";
$pw-icon-interval: "\e909";
$pw-icon-ip: "\e905";
$pw-icon-mode: "\e906";
$pw-icon-motorstrom: "\e912";
$pw-icon-naht-nr: "\e907";
$pw-icon-nok: "\e910";
$pw-icon-ok: "\e915";
$pw-icon-ok_baseline: "\e911";
$pw-icon-process: "\e90a";
$pw-icon-status: "\e90e";
$pw-icon-u: "\e90c";
$pw-icon-upload: "\e90d";
$pw-icon-wirefeeder: "\e90f";

