﻿/***  Bootstrap color scheme ***/
$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee
$gray-text: #a6a6a6;
$white-text: #ffffff;
$white-background: #ffffff;
$black-text: #000000;
$gray-company: #4b4b4d;
$company-blue: #8797b4;
$company-red: #e2001a;

$btn-background-gray: #4d4d4d;
$btn-background-gray-active: #575757;
$btn-highlight-color: #8797b4;
$btn-highlight-color-light: #8797b4;
$toggle-color: #8797b4;
$toggle-color-dark: darken(#8797b4, 6.5%);
$table-header: #dddddd;

//Button redesign
$button-text-color-normal: #000;
$button-text-color-important: #ffffff;
$button-text-color-critical: #ffffff;
$button-background-color-normal: #eceded;
$button-background-color-important: #4b4b4d;
$button-background-color-critical: #e2001a;


$brand-primary: #8797b4; //darken(#428bca, 6.5%); // #337ab7
$brand-success: #5cb85c;
$brand-info: #8797b4;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;

$nav-tabs-border-color: #ddd;

$companyColor: #e2001a;

$table-bg-accent: #f9f9f9;
$table-bg-hover: #f5f5f5;
$table-border-color: #ddd;

// Our variations/additions
$greyMediumLightDarker: #C6C7C8;
$grayText: $gray-dark;
$activeColor: $brand-primary;


/***  Welding chart series ***/
// KEEP IN SYNC WITH Colors.cs
$seriesColorBlue: #2196f3;
$seriesColorRed: #f44336;
$seriesColorGreen: #4caf50;
$seriesColorLime: #cddc39;
$seriesColorViolet: #b266ff;
$seriesColorOrange: #ffee1b;


//Dashboard
$dashboard-border: rgba(0,0,0,0.14);
$dashboard-light-text: rgba(0,0,0,0.4);

//InfoPanel
$infoPanel-Background: #ffffa3;

//validation
$validationErrorColor: #a94442;

//Tooltips
$tooltipIconColor: #8797b4;

$tooltip-color: #fff;
//** Tooltip background color
$tooltip-bg: #000;


$navbarBgColor: #4b4b4d;

/* helper-classes for common colors */
.color-gray{
    color: $gray-company;
}

.color-info{
    color: $brand-info;
}

.color-error{
    color: $company-red;
}