﻿// 1. Import the initial variables
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

//Variables for Bulma (overrides)
$primary: #8797b4;
$primary-invert: #ffffff;
$family-primary: Roboto, Helvetica, Arial, sans-serif;

// 5. Add new color variables to the color map.
@import "~bulma/sass/utilities/derived-variables";

// 6. Import the rest of Bulma
@import "~bulma/bulma";